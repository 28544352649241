<template>
  <div :style="conheight">
    <div style="height:100%;width:100%" id="container" width tabindex="0"></div>
    <div class="search-div">
      <el-input id="tipInput" v-model="inputSearchVal" placeholder="请输入搜索关键字">
        <template slot="prepend">关键字：</template>
      </el-input>
      <div id="panel"></div>
    </div>
    <div class="tooldiv">
      <button class="button" @click="Draw()">绘制</button>
      <!-- <button class="button" @click="Save()">保存</button> -->
    </div>
  </div>
</template>

<script>
import AMap from 'AMap'

export default {
  name: 'mapDetail',
  data() {
    return {
      // 地图map对象
      map: null,
      // 组件
      autoCompleteComponent: null,
      placeSearchComponent: null,
      mouseTool: null,
      driving: undefined, // 路线
      // 样式
      conheight: {
        height: 'calc(100vh - 105px)',
        width: ''
      },
      // marker点集合
      markerList: [],
      mapInput: '',
      inputSearchVal: '',
      areaRanges: []
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    rightWidth() {
      let leftWidth = this.isCollapse ? '64' : '200';
      return (this.conheight.width - leftWidth) + 'px'
    }
  },
  mounted() {
    this.init();
    this.addMarker();
    this.mapSearchInit();
    this.LoadAreaRanges();
  },
  methods: {
    /** 处理宽 */
    handleResize(event) {
      this.conheight.width = document.documentElement.clientWidth;
      this.conheight.height = document.documentElement.clientHeight
    },
    /** 初始化map对象 */
    init() {
      let map = new AMap.Map('container', {
        // center: [116.397428, 39.90923],
        resizeEnable: true,
        zoom: 15,
        lang: 'cn'
      })
      AMap.plugin(['AMap.ToolBar', 'AMap.Scale'], function () {
        map.addControl(new AMap.ToolBar())
        map.addControl(new AMap.Scale())
      });
      this.map = map;
    },
    drivingFn(location) {
      AMap.plugin(['AMap.Driving'], () => {
        this.driving = new AMap.Driving({
          // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
          map: this.map,
          panel: "panel",
          policy: AMap.DrivingPolicy.LEAST_TIME
        })
        let start = [116.379028, 39.865042], end = [location.lng, location.lat]
        this.driving.search(start, end, function (status, result) {
          // 未出错时，result即是对应的路线规划方案
          console.log(status, result)
        })
      })
    },
    /** 打开marker消息窗体 */
    openInfo(positionResult, pointData, poi) {
      let str = `
                <div class='input-card content-window-card'>
                    <div style="padding:7px 0px 0px 0px;">
                    <h4>${poi.name}</h4>
                    <p class='input-item'>地址 :${poi.district} ${poi.address}</p>
                    </div>
                    </div>`

      let infoWindow = new AMap.InfoWindow({
        content: str,
        offset: new AMap.Pixel(10, -25)
      });
      infoWindow.open(this.map, positionResult.lnglat);
    },
    /** 添加marker */
    addMarker() {
      let that = this;
      var marker = new AMap.Marker({
        position: new AMap.LngLat(116.397428, 39.90923),
        title: '北京',
        extData: { id: '123456' }
      });
      //绑定点击事件
      marker.on('click', positionResult => {
        console.log(positionResult);
        let pointData = positionResult.target.getExtData();
        that.openInfo(positionResult, pointData); // 点击浮点显示弹窗
      });
      //
      // this.markerList.push(marker);
      this.map.add(this.markerList);
    },
    /** 初始化搜索工具 */
    mapSearchInit() {
      const that = this;
      // 绑定自动提示
      AMap.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], () => {
        var autoOptions = {
          input: "tipInput",
          city: '全国',
        }
        var autoCompleteComponent = new AMap.Autocomplete(autoOptions);
        // 监听选中项
        AMap.event.addListener(autoCompleteComponent, "select", (data) => {
          console.log(data);
          if (data.poi.location != undefined) {
            //定位到中心点
            that.map.setCenter(data.poi.location);
            // 获取数据，对数据进行操作如：添加marker等
            if (data.poi.location && data.poi.location.lat) {
              // 路线
              // this.drivingFn(data.poi.location,[data.poi.location.lng,data.poi.location.lat])


              let marker = new AMap.Marker({
                position: new AMap.LngLat(data.poi.location.lng, data.poi.location.lat),
                title: data.poi.name,
                // extData: {id:'123456'}
              });
              marker.on('click', positionResult => {
                let pointData = positionResult.target.getExtData();
                let poi = data.poi
                that.openInfo(positionResult, pointData, poi); // 点击浮点显示弹窗
              });
              this.markerList.push(marker);
              this.map.add(this.markerList);
            }
          }
        })
        that.autoCompleteComponent = autoCompleteComponent;
        // 注册placeSearch组件
        that.placeSearchComponent = new AMap.PlaceSearch({
          // city 指定搜索所在城市，支持传入格式有：城市名、citycode和adcode
          // city: '北京'
        })
      });
    },
    /** 关键词搜索 */
    searchKeyword() {
      this.placeSearchComponent.search(this.inputSearchVal, function (status, result) {
        // 查询成功时，result即对应匹配的POI信息
        console.log(status);
        console.log(result);
      })
    },
    InitMouseTool() {
      //初始化鼠标绘制工具
      this.mouseTool = new AMap.MouseTool(this.map);
    },
    Draw() {
      var api = this.$api;
      var message = this.$message;
      var showInputBox = this.$showInputBox;
      var map = this.map;
      message({
        message: "点击左键开始绘制，右键结束！",
        type: 'info'
      })

      this.mouseTool.polygon({
        strokeColor: "#FF33FF",
        strokeOpacity: 1,
        strokeWeight: 6,
        strokeOpacity: 0.2,
        fillColor: '#1791fc',
        fillOpacity: 0.4,
        strokeStyle: "solid",
      });

      this.mouseTool.on('draw', function (event) {
        var value = { name: '', remark: '' }
        showInputBox({
          caption: "请输入：",
          value,
          callback: (data) => {
            if (data.isconfirm) {
              let params = {
                method: "Modify",
                proc: "USP_Update_MarkRange",
                pars: [
                  { name: data.name, positions: event.obj.getPath().map(x => [x.lng, x.lat]), remark: data.remark }
                ],
                tmscode: "89569",
                loginsite: "全部",
                token: ""
              }

              api(params, { loading: false }).then(res => {
                message({
                  message: res.msg,
                  type: 'success'
                })

                location.reload();
              })
            } else {
              map.remove([event.obj])
            }
          }
        })
      })
    },
    LoadAreaRanges() {
      let params = {
        method: "Query",
        proc: "QSP_GET_MarkRange",
        pars: [],
        tmscode: "89569",
        loginsite: "全部",
        token: ""
      }
      this.$api(params, { loading: false }).then(res => {
        if (res && res.msg) {
          res.msg.map(x => {
            this.areaRanges.push({ id: x.id, name: x.name, positions: x.positions });
          });
        }

        // this.InitMarks();
        this.InitText();
        this.InitMouseTool();
        this.InitAreaRange();
      })
    },
    InitText() {
      var map = this.map;
      this.areaRanges.forEach(element => {
        // 创建纯文本标记
        var text = new AMap.Text({
          text: element.name,
          anchor: 'center', // 设置文本标记锚点
          cursor: 'pointer',
          style: {
            fontSize: 12,
            fontWeight: 'normal',
            strokeWidth: 2,
            fold: true,
            padding: '2, 5',
            background: "transparent",
            border: "none"
          },
          position: this.getAreaCenter(JSON.parse(element.positions)),
        });

        text.setMap(map);
      });
    },
    InitAreaRange() {
      var api = this.$api;
      var message = this.$message;
      var map = this.map;
      var polygons = [];
      var contextMenu = new AMap.ContextMenu();
      var showInputBox = this.$showInputBox;
      contextMenu.addItem("修改", function () {
        // 缩放地图到合适的视野级别
        map.setFitView()
        contextMenu.polyEditor = new AMap.PolyEditor(map, contextMenu.polygon);
        contextMenu.polyEditor.open();
      }, 0);

      contextMenu.addItem("保存", function () {
        if (contextMenu.polyEditor) {
          contextMenu.polyEditor.close();
          var value = { name: contextMenu.data.name, remark: contextMenu.data.reamrk }
          showInputBox({
            caption: "请输入：",
            value,
            callback: (boxdata) => {
              if (boxdata.isconfirm) {
                let params = {
                  method: "Modify",
                  proc: "USP_Update_MarkRange",
                  pars: [
                    { id: contextMenu.data.id, name: boxdata.name, positions: contextMenu.polygon.getPath().map(x => [x.lng, x.lat]), remark: boxdata.reamrk }
                  ],
                  tmscode: "89569",
                  loginsite: "全部",
                  token: ""
                }
                api(params).then(res => {
                  message({
                    message: res.msg,
                    type: 'success'
                  })

                  location.reload();
                })
              }
            }
          })
        }
      }, 1);

      contextMenu.addItem("删除", function () {
        let params = {
          method: "Modify",
          proc: "USP_Delete_MarkRange",
          pars: [
            { id: contextMenu.data.id }
          ],
          tmscode: "89569",
          loginsite: "全部",
          token: ""
        }
        api(params).then(res => {
          message({
            message: res.msg,
            type: 'success'
          })

          location.reload();
        })
      }, 2);

      this.areaRanges.forEach(element => {
        var polygon = new AMap.Polygon({
          path: JSON.parse(element.positions),//设置多边形边界路径
          strokeColor: "#FF33FF", //线颜色
          strokeOpacity: 0.2, //线透明度
          strokeWeight: 3,    //线宽
          fillColor: "#1791fc", //填充色
          fillOpacity: 0.35//填充透明度
        });

        polygon.on('rightclick', function (e) {
          contextMenu.open(map, e.lnglat);
          contextMenu.data = element;
          contextMenu.polygon = polygon;
        });

        polygons.push(polygon);
      });

      var overlayGroup = new AMap.OverlayGroup(polygons);
      this.map.add(overlayGroup);
    },
    InitMarks() {
      var textStyle = {
        fontSize: 12,
        fontWeight: 'normal',
        fillColor: '#22886f',
        strokeColor: '#fff',
        strokeWidth: 2,
        fold: true,
        padding: '2, 5',
      };

      var LabelsData = [];
      this.areaRanges.forEach(item => {
        LabelsData.push({
          name: item.name,
          position: this.getAreaCenter(JSON.parse(item.positions)),
          zooms: [10, 20],
          opacity: 1,
          zIndex: 1,
          fold: true,
          style: textStyle,
          text: {
            // 要展示的文字内容
            content: item.name,
            // 文字方向，有 icon 时为围绕文字的方向，没有 icon 时，则为相对 position 的位置
            direction: 'right',
            // 在 direction 基础上的偏移量
            offset: [-20, -5],
            // 文字样式
            style: {
              // 字体大小
              fontSize: 18,
              // 字体颜色
              fillColor: '#22886f',
              strokeColor: '#fff',
              strokeWidth: 2,
              fold: true,
              padding: '2, 5',
            }
          }
        })
      })

      var markers = [];
      var allowCollision = false;
      var layer = new AMap.LabelsLayer({
        zooms: [3, 20],
        zIndex: 1000,
        // collision: false,
        // 设置 allowCollision：true，可以让标注避让用户的标注
        allowCollision,
      });
      layer.add(markers);
      // 图层添加到地图
      this.map.add(layer);
      // 初始化 labelMarker
      for (var i = 0; i < LabelsData.length; i++) {
        var curData = LabelsData[i];
        curData.extData = {
          index: i
        };

        var labelMarker = new AMap.LabelMarker(curData);
        markers.push(labelMarker);
      }
      // 将 marker 添加到图层
      layer.add(markers);
    },
    getAreaCenter(location) {
      var total = location.length;
      var X = 0,
        Y = 0,
        Z = 0;
      location.forEach(lnglat => {
        var lng = (lnglat[0] * Math.PI) / 180;
        var lat = (lnglat[1] * Math.PI) / 180;
        var x, y, z;
        x = Math.cos(lat) * Math.cos(lng);
        y = Math.cos(lat) * Math.sin(lng);
        z = Math.sin(lat);
        X += x;
        Y += y;
        Z += z;
      });
      X = X / total;
      Y = Y / total;
      Z = Z / total;

      var Lng = Math.atan2(Y, X);
      var Hyp = Math.sqrt(X * X + Y * Y);
      var Lat = Math.atan2(Z, Hyp);
      return [
        parseFloat((Lng * 180) / Math.PI),
        parseFloat((Lat * 180) / Math.PI),
      ];
    },
  },
}
</script>

<style lang="scss" scoped>
.search-div {
  position: absolute;
  align-items: center;
  left: 100px;
  top: 50px;
  width: 300px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 2px 2px 2px #ccc;
  overflow: hidden;

  ::v-deep #panel {
    background-color: white;
    overflow-y: auto;
    top: 85px;
    left: 100px;
    width: 300px;

    .amap-call {
      background-color: #009cf9;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .amap-lib-driving {
      max-height: 500px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      overflow: scroll;
    }
  }
}

.tooldiv {
  position: absolute;
  align-items: center;
  left: 410px;
  top: 50px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 2px 2px 2px #ccc;
  overflow: hidden;
  background-color: rgba(255, 0, 0, 0);

  .button {
    width: 50px;
    margin: 5px;
  }
}
</style>
